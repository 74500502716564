$primary: #ffffff;
// $primary: #27374d;
$secondary: #dde6ed;
$main-color: #7a1de0;
// $primary: #4a6cd1;
@mixin main-button($color, $border-color) {
  //   margin-top: 50px;
  font-family: "Poppins";
  padding: 0.5rem;
  color: white;
  background-color: $color;
  border-radius: 5px;
  border: 2px solid $border-color;
}
@mixin font($font, $color, $weight, $size, $height: normal) {
  font-family: $font;
  color: $color;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: $primary; /* Track color */
  border-radius: 4px;
  border: 1px solid #676767bf;
}

::-webkit-scrollbar-thumb {
  // lighten is preduild function that makes the color light as the give value
  background-color: lighten($main-color, 20); /* Thumb color */
  // background-color: #b4a7b7bf; /* Thumb color */
  border-radius: 4px; /* Rounded corners */
}
body {
  // for gray variant
  // background: #ebf0f4;
  // for purple variant
  background: lighten($main-color, 47);
  // background: #f7f1ff;

  filter: blur(0.4);
  .full-screen {
    min-width: 100vw;
    background: $primary;
    background-image: url("./Rectangle_voilet.svg");
    background-repeat: no-repeat;
    // background-position: -60px;
    @media only screen and (max-width: 767px) {
      background-image: url("");
      background-color: $main-color;
    }

    // background: linear-gradient(142deg, $primary 0%, rgb(2, 31, 144) 100%);
  }
  .form-box {
    min-height: 100vh;
    position: relative;
    max-width: 1440px;
    margin: auto;
    max-height: 98vh;

    .sign-in {
      position: absolute;
      top: 2rem;
      right: 2rem;
      color: white;
      //   color: white;

      .sign-link {
        @include main-button(black, transparent);
        text-decoration: none;
        margin-left: 1rem;
      }
    }

    .inner-from-box {
      max-height: 98vh;
      // border: 0px solid;

      border-radius: 1rem !important;
      // max-width: 10%;

      // border: 0px solid;
      // border: 5px solid $secondary;
      // border-bottom: 1px solid $secondary;
      // border-right: 3px;
      // border-left: 3px;
      // border-radius: 2rem;

      // background-color: transparent;
      background-color: $secondary;
      background-color: rgb(255, 255, 255);
      @media only screen and (max-width: 576px) {
        max-width: 100%;
        min-width: 60%;
      }
      InputLabel {
        color: $secondary;
        background-color: #179bd7;
        // background-color: #179bd7;
      }
      h4 {
        @include font("Poppins", transparentize(black, 0.5), 600, 35px);
        // @include font("Poppins", rgba(0, 0, 0, 0.527), 600, 35px);
      }
      hr {
        color: black;
        opacity: 1;
        height: 2px;
        width: 40%;
        display: flex;
      }

      input,
      .submit-button,
      .input-group {
        border-radius: 5px;
        border: 0px solid;
        padding: 1rem;
        margin-bottom: 0rem;
        margin-top: 0rem;
        min-width: 15rem;
        min-width: 15rem;
        height: 0.5rem;
        background-color: rgb(240 240 240);

        @media only screen and (max-width: 576px) {
          max-width: 100%;
          min-width: 70%;
        }
      }
      .link {
        text-decoration: none;
      }
      .selected-flag {
        // border: none;
        z-index: 13;
        width: 30px;
        height: 100%;
        padding: 0 0 0 8px;
        border-radius: 3px 0 0 3px;
        right: -275px;
        /* background-color: black; */
        /* color: white; */
        // margin-bottom: 2rem;
      }
      .flag,
      .arrow {
        position: relative;
        top: 0;
        right: 15px;
      }
      .country-list {
        top: -325px;
        left: -40px;
      }
      .submit-button {
        margin-top: 2rem;
        height: 2.5rem;
        // color: #27374d !important;
        color: $secondary !important;
        // @include main-button(#179bd7, transparent);
        @include main-button($main-color, transparent);
      }
      border-radius: 5px;
      padding: 01rem 2rem 3.5rem 2rem;
      max-width: 30rem;
      height: fit-content;
      -webkit-box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.25);
    }
  }
}
