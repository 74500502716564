::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #ffffff; /* Track color */
  border-radius: 4px;
  border: 1px solid rgba(103, 103, 103, 0.7490196078);
}

::-webkit-scrollbar-thumb {
  background-color: #af76ed; /* Thumb color */
  border-radius: 4px; /* Rounded corners */
}

body {
  background: #f6f0fd;
  filter: blur(0.4);
}
body .full-screen {
  min-width: 100vw;
  background: #ffffff;
  background-image: url("./Rectangle_voilet.svg");
  background-repeat: no-repeat;
}
@media only screen and (max-width: 767px) {
  body .full-screen {
    background-image: url("");
    background-color: #7a1de0;
  }
}
body .form-box {
  min-height: 100vh;
  position: relative;
  max-width: 1440px;
  margin: auto;
  max-height: 98vh;
}
body .form-box .sign-in {
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: white;
}
body .form-box .sign-in .sign-link {
  font-family: "Poppins";
  padding: 0.5rem;
  color: white;
  background-color: black;
  border-radius: 5px;
  border: 2px solid transparent;
  text-decoration: none;
  margin-left: 1rem;
}
body .form-box .inner-from-box {
  max-height: 98vh;
  border-radius: 1rem !important;
  background-color: #dde6ed;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 1rem 2rem 3.5rem 2rem;
  max-width: 30rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 576px) {
  body .form-box .inner-from-box {
    max-width: 100%;
    min-width: 60%;
  }
}
body .form-box .inner-from-box InputLabel {
  color: #dde6ed;
  background-color: #179bd7;
}
body .form-box .inner-from-box h4 {
  font-family: "Poppins";
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 35px;
  line-height: normal;
}
body .form-box .inner-from-box hr {
  color: black;
  opacity: 1;
  height: 2px;
  width: 40%;
  display: flex;
}
body .form-box .inner-from-box input,
body .form-box .inner-from-box .submit-button,
body .form-box .inner-from-box .input-group {
  border-radius: 5px;
  border: 0px solid;
  padding: 1rem;
  margin-bottom: 0rem;
  margin-top: 0rem;
  min-width: 15rem;
  min-width: 15rem;
  height: 0.5rem;
  background-color: rgb(240, 240, 240);
}
@media only screen and (max-width: 576px) {
  body .form-box .inner-from-box input,
body .form-box .inner-from-box .submit-button,
body .form-box .inner-from-box .input-group {
    max-width: 100%;
    min-width: 70%;
  }
}
body .form-box .inner-from-box .link {
  text-decoration: none;
}
body .form-box .inner-from-box .selected-flag {
  z-index: 13;
  width: 30px;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 3px 0 0 3px;
  right: -275px;
  /* background-color: black; */
  /* color: white; */
}
body .form-box .inner-from-box .flag,
body .form-box .inner-from-box .arrow {
  position: relative;
  top: 0;
  right: 15px;
}
body .form-box .inner-from-box .country-list {
  top: -325px;
  left: -40px;
}
body .form-box .inner-from-box .submit-button {
  margin-top: 2rem;
  height: 2.5rem;
  color: #dde6ed !important;
  font-family: "Poppins";
  padding: 0.5rem;
  color: white;
  background-color: #7a1de0;
  border-radius: 5px;
  border: 2px solid transparent;
}
/*# sourceMappingURL=login.css.map */